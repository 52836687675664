export function initializeAdobeTracking(isProd) {
  const log = window.tv2.utils.setupLog('📈 Adobe Tracking');
  window.DTMQ = window.DTMQ || [];
  window.dataLayer = window.dataLayer || {};

  log.group('Initial setup');
  if (window.tv2messages) {
    log.info('Using tv2messages to get traceIds (adobe-vid)');
    window.DTMQ.push(() => {
      try {
        const { traceIds } = window.tv2messages.getTopics();
        if (traceIds) {
          // We have cached response from auth0, so we know if we're logged in or not
          if (traceIds.data) {
            window.dataLayer.customerId = traceIds.data['adobe-vid'];
            window.dataLayer.loginStatus = 'logged in';
          } else {
            // Published topic, but "data === null" means we're logged out
            window.dataLayer.loginStatus = 'not logged in';
          }
        } else {
          // Auth0 does not have login status in cache, so we set loginstatus to not responding
          window.dataLayer.loginStatus = 'userdata not responding';
        }

        log.info(`Login status is: ${window.dataLayer.loginStatus}`, {
          traceIds,
          dataLayerLoginStatus: window.dataLayer.loginStatus,
        });
      } catch (error) {
        log.error(
          'Failed to get login status from tv2messages for use in Adobe tracking',
          { error },
        );
      }

      log.info(
        'Adobe Launch fires _satellite.track("pageLoad") from window.DTMQ',
      );
      // eslint-disable-next-line no-underscore-dangle
      window._satellite.track('pageLoad');
    });
  } else {
    log.error(
      'tv2messages is not available, pushing _satellite.track("pageLoad") without logged in status',
    );
    window.dataLayer.loginStatus = 'userdata not responding';
    window.DTMQ.push(() => {
      log.info(
        'Adobe Launch fires _satellite.track("pageLoad") from window.DTMQ',
      );
      // eslint-disable-next-line no-underscore-dangle
      window._satellite.track('pageLoad');
    });
  }

  const articleBody = window.document.querySelector(
    '[data-adobe-context="article"]',
  );

  function checkArticleBottomReached() {
    window.requestAnimationFrame(() => {
      const isArticleComplete =
        articleBody?.getBoundingClientRect().bottom < window.innerHeight;

      if (isArticleComplete) {
        // eslint-disable-next-line  no-underscore-dangle
        window._satellite.track('articleComplete');
        log.info('Tracking article complete');
        window.removeEventListener('scroll', checkArticleBottomReached);
      }
    });
  }

  if (articleBody) {
    window.addEventListener('scroll', checkArticleBottomReached);
  }

  // Adds click event handler for data-adobe-interaction-event attribute
  window.addEventListener('click', function _interactionEventHandler(event) {
    try {
      const label = event.target.getAttribute('data-adobe-interaction-event');
      if (!label) {
        return;
      }
      // eslint-disable-next-line no-underscore-dangle
      window._satellite.track('user interaction', { label });
    } catch (err) {
      log.error('Unable to properly trace interaction event', err);
    }
  });

  log.groupEnd();

  const stagePfx = 'EN8c8b419e553c414a9a9484c1f332028f-staging'; // For dev and stage
  const prodPfx = 'ENf03a4566112c40bfb2161ff69620c8e5'; // for production
  const adobeScriptUrl = `https://assets.adobedtm.com/launch-${
    isProd ? prodPfx : stagePfx
  }.min.js`;

  window.tv2.utils.loadScript(adobeScriptUrl);
}
